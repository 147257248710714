import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Mittagstisch"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Montag Mittags`}</h2>
    <p>{`gibt es bei uns in der Küche Abwechslungsreiches.`}</p>
    <p>{`Als Küchenkollektiv N46 kannst du immer auf uns bauen.
Langweilige Küche gibt es bei uns nicht, hier wird noch gewagt, geklotzt und gekleckert.
Komm vorbei und besser du machst eine Vorbestelung, sonst ist es weg!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      